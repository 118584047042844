import react, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  theoryArticles,
  requestArticle,
  theoryUrl,
} from "../hooks/RestClient.jsx";
import { useTranslation } from "react-i18next";
import { FilterMenu } from "../components/FilterMenu.jsx";
import Loading from "../components/Loading.jsx";
import { TagCloud } from "react-tagcloud";
import Popup from "reactjs-popup";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/uk";
import { useMediaQuery } from "react-responsive";
import {
  MenuItem,
  Divider,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import {
  Sort as SortIcon,
  CropSquareSharp as DefaultIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import "../css/personlist.scss";
import "../css/theory.scss";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "../hooks/useThemeContext.jsx";
/**
 * Popup component
 * the modal to display the notes details
 *
 * @param {boolean} props.popupState - The state of the popup, indicating whether it is open (true) or closed (false).
 * @param {Function} props.setPopupState - The function to set the state of the popup.
 * @param {Object} props.popupData - The data to be displayed in the popup passed from "Notes component" by the user clicking a slected note, if there wasnt any note slected (note link shared or page reload) data need to be fetched from the api.
 * @param {number} props.currentPage - used to save currentPage from the last used path before the Popup to return to when closing the Popup
 * @param {string} props.tagsSelected - used to save tagsSelected from the last used path before the Popup to return to when closing the Popup
 * @returns {JSX.Element}
 * @constructor
 */
const TheoryPopup = ({
  popupState,
  setPopupState,
  popupData,
  currentPage,
  tagsSelected,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const t = useTranslation().t;
  const lang = t("lang");
  const { id } = useParams();
  const [cardData, setCardData] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);

  // fetching is not used unless no data is passed down to the popup, typically this happens when reloading the page or directly visiting `theory/:type/:id`
  useEffect(() => {
    const elements = document.querySelectorAll("[autoFocus]");
    elements.forEach((el) => el.removeAttribute("autoFocus"));
    const fetchData = async () => {
      try {
        setCardLoading(true);
        const res = await requestArticle(theoryUrl(), id);
        if (res.error) {
          console.error(res.error);
        }
        console.info(" fetch done");
        setCardData(res);
      } catch (error) {
        console.error(error);
      } finally {
        setCardLoading(false);
      }
    };

    // this check if the data of the org is pass down to the pop up if it's not it fetch the data from the api  let orgData = {};
    const cardInfoCheck = () => {
      if (popupData) {
        if (popupData.id == id && popupState == true) {
          setCardData(popupData);
          setCardLoading(false);
        } else if (popupData.id !== id && popupState == true) {
          fetchData();
        }
      } else if (!popupData && popupData.length == 0 && popupState == true) {
        fetchData();
      }
    };
    cardInfoCheck();

    const disableScrolling = () => {
      if (popupState == true) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    };
    disableScrolling();
  }, [id, popupState, cardData.id, popupData]);

  const closeModal = () => {
    setPopupState(false);

    if (currentPage && tagsSelected) {
      navigate(`/theory?page=${currentPage}&$tag=${tagsSelected}`);
    } else if (currentPage) {
      navigate(`/theory?page=${currentPage}`);
    } else {
      navigate(`/theory`);
    }

    setCardData([]);
  };
  const itemType = cardData.item_type || "";
  const itemSubtype = cardData.additional?.subtype || "";
  const dateFormat = lang == "en" ? "MMMM DD,YYYY" : "DD MMMM YYYY";
  const addedDate =
    dayjs(cardData.added_at).locale(lang).format(dateFormat) || "";
  const title = cardData.title?.[lang] || "";
  const ogAuthor = cardData.additional?.author || "";
  const ogLinks = cardData.additional?.urls;
  const ogPublishedDate = cardData.additional?.date_published || "";

  const getContent = () => {
    if (cardData?.content?.[lang]) {
      return cardData?.content?.[lang];
    } else if (!cardData?.content?.[lang]) {
      if (cardData?.content?.ru) {
        return cardData?.content?.ru;
      } else if (cardData?.content?.uk) {
        return cardData?.content?.uk;
      } else if (cardData?.content?.en) {
        return cardData?.content?.en;
      }
    }
    return "";
  };

  return (
    <Popup
      open={popupState}
      closeOnDocumentClick
      onClose={closeModal}
      className={itemType}
    >
      {(!cardLoading && (
        <div className={`continier ${itemType} ${theme}`} autoFocus>
          <div className="popupHeader">
            <div className="type">
              <p>{itemType}</p>
              {itemType == "article" && <p>{itemSubtype}</p>}
            </div>
            <p>{addedDate}</p>
          </div>
          <div className="content">
            {title && itemType != "article" && <h1>{title}</h1>}
            <br />
            <ReactMarkdown>{getContent()}</ReactMarkdown>
          </div>
          {itemType == "article" && (
            <div className="popupFooter">
              <div className="originalAuthor">
                <p>
                  {`Original text was published by @${ogAuthor} in his Telegram
            channel on ${ogPublishedDate}: `}{" "}
                </p>
                {ogLinks?.map((link, i) => (
                  <Link
                    key={i}
                    to={link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="linkButton"
                  >
                    {t("Original text")} {i + 1 + " "}
                    <OpenInNewIcon fontSize="10px" />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      )) || (
        <div className={`continier ${theme}`} autoFocus>
          <Loading />
        </div>
      )}
    </Popup>
  );
};

/**
 * Notes component
 * Renders notes with optional modal and theme configurations.
 *
 * @param {boolean} props.modalState - The state of the modal, indicating whether it is open or closed.
 * @returns {JSX.Element}
 * @constructor
 */

export const Theory = ({ modalState }) => {
  const [loading, setLoading] = useState(true);
  const [tagCloudShuffle] = useState(true);

  const theme = useTheme();
  const t = useTranslation().t;
  const lang = t("lang");

  const [theoryData, setTheoryData] = useState([]);
  const [theoryCardsData, setTheoryCardsData] = useState([]);
  const [fetchedTags, setFetchedTags] = useState([]);

  const [popupState, setPopupState] = useState(modalState);
  const [modalData, setModalData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

  // todo: i need to fix nav preview
  // to fetch links for the theory nav
  const paths = location.pathname.split("/").filter((path) => path);

  // the values that will be used for the api request
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [tagsSelected, setTagsSelected] = useState(
    searchParams.get("tag") || null
  );
  //todo change it to 9 and dynamically scale for the display
  const pageSize = 12;
  const totalPages = Math.ceil(theoryData.total / pageSize);
  const [sortBy, setSortBy] = useState(null);
  const [sortingDirection, setSortingDirection] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const filters = {
          sortBy: sortBy,
          sortingDirection: sortingDirection,
          currentPage: currentPage,
          pageSize: pageSize,
          tagsSelected: tagsSelected,
        };
        const results = await theoryArticles(theoryUrl(), filters);

        const fixTags = results.all_tags?.map((tag) => ({
          value: tag.name,
          id: tag.id,
          parent_id: tag.parent_id,
          selected: true,
          count: Math.floor(Math.random() * 31) + 10,
        }));
        setFetchedTags(fixTags);
        setTheoryData(results);
        setTheoryCardsData(results.page);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().then(() => {
      console.log("fetchData() done");
    });
  }, [currentPage, pageSize, sortBy, sortingDirection, tagsSelected]);

  // to control the collapsible side menu components
  const [openComponent, setOpenComponent] = useState("tags"); // Default to tags being open
  const toggleCollapse = (component) => {
    setOpenComponent((prevComponent) =>
      prevComponent === component ? "" : component
    );
  };

  // to control sortby button
  const [menuAnchor, setMenuAnchor] = useState({});
  const openMenu = (menuId, event) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: event.currentTarget,
    }));
  };
  const closeMenu = (menuId) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: null,
    }));
  };

  return (
    <div className="notesPage">
      <div className="pageHeader">
        <div className="theoryHeader__bg">
          <div className="theoryHeader__bgOverlay">
            <div className="theoryHeader__container">
              <h2>{t("Theory")}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="pageContent">
        <aside>
          <div
            className={`tags collapse ${
              openComponent === "tags" ? "collapse-Opened" : "collapse-cloesd"
            }`}
          >
            <div className="top">
              <h4 onClick={() => toggleCollapse("tags")}>{t("tags")}</h4>
              <div className="buttons">
                <IconButton
                  onClick={(event) => openMenu("tagsSortByAnchor", event)}
                >
                  <SortIcon />
                </IconButton>
                <FilterMenu
                  id="customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "customized-button",
                  }}
                  anchorEl={menuAnchor["tagsSortByAnchor"]}
                  open={Boolean(menuAnchor["tagsSortByAnchor"])}
                  onClick={() => closeMenu("tagsSortByAnchor")}
                >
                  {tagsSortingOptions.map((option, i) => [
                    <MenuItem
                      key={i}
                      onClick={() => {
                        setSortBy(option.StateToSet);
                        setSortingDirection(option.SortingDirection);
                      }}
                      disableRipple
                    >
                      {option.icon}
                      {t(option.lable)}
                    </MenuItem>,
                    option.divider && (
                      <Divider key={`${i}-divider`} sx={{ my: 0.5 }} />
                    ),
                  ])}
                </FilterMenu>
                <IconButton onClick={() => toggleCollapse("tags")}>
                  <ExpandMoreIcon className="rotate" />
                </IconButton>
              </div>
            </div>
            {(!loading && (
              <div className="content">
                {(fetchedTags?.length > 0 && (
                  <TagCloud
                    minSize={14}
                    maxSize={24}
                    shuffle={tagCloudShuffle}
                    tags={fetchedTags}
                    colorOptions={{
                      luminosity: theme == "dark-mode" ? "light" : "dark",
                      hue: "blue",
                    }}
                    // todo: try to make it show name insted of id
                    onClick={(tag) => {
                      navigate(`/theory?page=${1}&tag=${tag.id}`);
                      setCurrentPage(1);
                      setTagsSelected(tag.id);
                    }}
                  />
                )) || (
                  <div className="content">
                    <p>no tags has been found</p>
                  </div>
                )}
              </div>
            )) || (
              <div className="content">
                <Loading />
              </div>
            )}
          </div>
          <div
            className={`sequences collapse ${
              openComponent === "sequences"
                ? "collapse-Opened"
                : "collapse-cloesd"
            }`}
          >
            <div className="top">
              <h4 onClick={() => toggleCollapse("sequences")}>
                {t("sequences")}
              </h4>{" "}
              <div className="buttons">
                <IconButton
                  onClick={(event) => openMenu("sequencesSortByAnchor", event)}
                >
                  <SortIcon />
                </IconButton>
                <FilterMenu
                  id="customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "customized-button",
                  }}
                  anchorEl={menuAnchor["sequencesSortByAnchor"]}
                  open={Boolean(menuAnchor["sequencesSortByAnchor"])}
                  onClick={() => closeMenu("sequencesSortByAnchor")}
                >
                  {sequencesSortingOptions.map((op, i) => [
                    <MenuItem
                      key={i}
                      onClick={() => {
                        // todo: setState
                      }}
                      disableRipple
                    >
                      {op.icon}
                      {t(op.lable)}
                    </MenuItem>,
                    op.divider && (
                      <Divider key={`${i}-divider`} sx={{ my: 0.5 }} />
                    ),
                  ])}
                </FilterMenu>

                <IconButton onClick={() => toggleCollapse("sequences")}>
                  <ExpandMoreIcon className="rotate" />
                </IconButton>
              </div>
            </div>

            <div className="content ComingSoon">
              <h3>{t("Coming soon")}...</h3>
            </div>
          </div>
        </aside>
        <div className="main">
          <div className="subnav">
            {isDesktopOrLaptop && (
              <nav>
                {paths.map((path, index) => {
                  const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
                  const isLast = index === paths.length - 1;

                  return isLast ? (
                    <span key={routeTo}> {path} </span>
                  ) : (
                    <span key={routeTo}>
                      <Link to={routeTo}> {path}</Link> &gt;
                    </span>
                  );
                })}
              </nav>
            )}
            <Stack spacing={2}>
              <Pagination
                count={totalPages || 1}
                page={currentPage}
                onChange={(e, value) => {
                  setCurrentPage(value);
                  if (!tagsSelected) {
                    navigate(`/theory?page=${value}`);
                  } else {
                    navigate(`/theory?page=${value}&tag=${tagsSelected}`);
                  }
                }}
              />
            </Stack>
          </div>
          {(!loading && (
            <div className="notes">
              {(theoryCardsData?.length > 0 &&
                theoryCardsData?.map((note) => {
                  const dateFormat =
                    lang == "en" ? "MMMM DD,YYYY" : "DD MMMM YYYY";
                  const addDate = dayjs(note.added_at)
                    .locale(lang)
                    .format(dateFormat);

                  const getContent = () => {
                    if (note?.content?.[lang]) {
                      return note?.content?.[lang];
                    } else if (!note.content[lang]) {
                      if (note.content.ru) {
                        return note.content.ru;
                      } else if (note.content.uk) {
                        return note.content.uk;
                      } else if (note.content.en) {
                        return note.content.en;
                      }
                    }
                    return "";
                  };
                  return (
                    <div
                      className={`note ${note.item_type}`}
                      key={note.id}
                      onClick={() => {
                        setModalData(note);
                        setPopupState(true);
                        navigate(`/theory/${note.item_type}/${note.id}`);
                      }}
                    >
                      <div className="type">
                        <p>{note.item_type}</p>
                        {note.item_type == "article" && (
                          <p>{note.additional?.subtype}</p>
                        )}
                      </div>
                      <div className="content">
                        <ReactMarkdown>{getContent()}</ReactMarkdown>
                      </div>
                      <div className="date">
                        <p>{addDate}</p>
                        <a>{`Read more ->`}</a>
                      </div>
                    </div>
                  );
                })) || (
                <div className="notes">
                  <p>no articles has been found</p>
                </div>
              )}
            </div>
          )) || (
            <div className="notes">
              <Loading />
            </div>
          )}
        </div>
      </div>
      <TheoryPopup
        popupState={popupState}
        setPopupState={setPopupState}
        popupData={modalData}
        currentPage={currentPage}
        tagsSelected={tagsSelected}
      />
    </div>
  );
};
export default Theory;

const tagsSortingOptions = [
  {
    lable: "Date",
    StateToSet: "added_at",
    SortingDirection: "asc",
    icon: <ArrowDownwardIcon />,
    divider: false,
  },
  {
    lable: "Date",
    StateToSet: "added_at",
    SortingDirection: "desc",
    icon: <ArrowUpwardIcon />,
    divider: false,
  },
];
const sequencesSortingOptions = [
  {
    lable: "Default",
    StateToSet: "id",
    SortingDirection: "asc",
    icon: <DefaultIcon />,
    divider: false,
  },
];
