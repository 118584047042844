import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

// import { Pagination } from "./Pagination.jsx";
import { Person } from "../components/Person.jsx";
import {
  personsUrl,
  requestPersons,
  requestPeopleFilters,
} from "../hooks/RestClient.jsx";

import Loading from "../components/Loading.jsx";

import "../css/personlist.scss";
// import "../css/them.scss";
import {
  FilterMenu,
  MultiSelectFilter,
  SelectFilter,
} from "../components/FilterMenu.jsx";

import { Button, MenuItem, Divider, Pagination, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Sort as SortIcon,
  CropSquareSharp as DefaultIcon,
  SortByAlpha as SortByAlphaIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  FilterAlt as FilterAltIcon,
} from "@mui/icons-material";
import log from "loglevel";
import IconButton from "@mui/material/IconButton";

import PersonIcon from "@mui/icons-material/Person";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FeedIcon from "@mui/icons-material/Feed";
import BusinessIcon from "@mui/icons-material/Business";
import MobileSearch from "../components/MobileSearch.jsx";

import { Search as SearchIcon } from "@mui/icons-material";

const DEFAULT_PAGE_SIZE = 25;

/** PersonList component
 * The main component of the app, populates `persons` array with data from REST API,
 * and renders Person components
 * @returns {JSX.Element}
 * @constructor
 */
export function PersonList() {
  const [persons, setPersons] = useState([]);
  const [filters, setFilters] = useState([]);
  // const [stats, setStats] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchPage, setSearchPage] = useState(false);

  const [sort_by, setSort] = useState("fullname.en");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const [status, setStatus] = useState(null);
  const [sex, setSex] = useState(null);

  const [flagsIds, setFlagsIds] = useState([]);
  const [expertiseIds, setExpertiseIds] = useState([]);
  const [groupsIds, setGroupsIds] = useState([]);
  const [selectedAge, setSelectedAge] = React.useState([null, null]);

  const t = useTranslation().t;
  const navigate = useNavigate();

  const isPhone = useMediaQuery({ maxWidth: 768 });
  const isTabletPortrait = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isTabletLandscape = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isCompactDesktop = useMediaQuery({ minWidth: 1280, maxWidth: 1440 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        log.debug(
          "PersonList.fetchData started with requestPersons[url=",
          personsUrl(),
          "; page=",
          currentPage,
          "; page_size=",
          DEFAULT_PAGE_SIZE,
          "]"
        );

        const filters = {
          sort_by: sort_by,
          sortingDirection: sortingDirection,
          status: status,
          sex: sex,
          age_min: selectedAge[0],
          age_max: selectedAge[1],
          // flagsIds: flagsIds,
          // expertiseIds: expertiseIds,
          // groupsIds: groupsIds,
        };
        const results = await requestPersons(
          personsUrl(),
          currentPage,
          DEFAULT_PAGE_SIZE,
          filters
        );

        setPersons(results.data);
        const filtersResults = await requestPeopleFilters(personsUrl());
        setFilters(filtersResults);
        setTotalPages(Math.ceil(results.total / DEFAULT_PAGE_SIZE));
        log.info(
          "PersonList.fetchData response [total=",
          results.total,
          "; page_size=",
          results.page_size,
          "; totalPages=",
          totalPages,
          "]"
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().then(() => log.debug("PersonList.fetchData done"));
  }, [
    currentPage,
    totalPages,
    sort_by,
    sortingDirection,
    status,
    sex,
    selectedAge,
  ]);

  // toggle to open and close @mui components
  const [menuAnchor, setMenuAnchor] = useState({});

  const openMenu = (menuId, event) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: event.currentTarget,
    }));
  };

  const closeMenu = (menuId) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: null,
    }));
  };

  const statusOptions = filters.status || [];
  const filtersAge = filters.age || [];
  const genderOptions = filters.gender || [];

  const expertsOptions = () => {
    return filters.bundles?.ExpertBundles.map((bundle) => ({
      value: bundle.id,
      label: "bundle.name[lang]",
    }));
  };
  const flagsOptions = () => {
    return filters.bundles?.FlagsBundles.map((bundle) => ({
      value: bundle.id,
      label: "bundle.name[lang]",
    }));
  };
  const groupsOptions = () => {
    return filters.bundles?.GroupsBundles.map((bundle) => ({
      value: bundle.id,
      label: "bundle.name[lang]",
    }));
  };
  const chunkArray = (arr = []) => {
    const getChunkSize = () => {
      if (isDesktop) return 5;
      if (isCompactDesktop) return 4;
      if (isTabletLandscape) return 3;
      if (isTabletPortrait) return 3;
      if (isPhone) return 2;
      return 5;
    };
    let chunkSize = getChunkSize();
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const personsChunks = chunkArray(persons);

  const [filtersTab, setFiltersTab] = useState("disabled");
  const [statsTab, setStatsTab] = useState("disabled");

  const handleTab = () => {
    setStatsTab("disabled");
    setFiltersTab(filtersTab === "disabled" ? "filters" : "disabled");
  };
  const handleStatsTab = () => {
    setFiltersTab("disabled");
    setStatsTab(statsTab === "disabled" ? "states" : "disabled");
  };

  return (
    <>
      {(!searchPage && (
        <div className="personlist-container">
          <div className="pageBanner">
            <div className="bannerBg">
              <div className="bannerBgOverlay">
                <div className="bannerContainer">
                  <h2>{t("people involved")}</h2>
                  <p>
                    {t("people involved text")}
                    <a href="mailto:contact@subjective.agency">
                      {t("do let us know!")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="filtreBAR">
            <FilterMenu
              id="customized-menu"
              MenuListProps={{
                "aria-labelledby": "customized-button",
              }}
              anchorEl={menuAnchor["sortByAnchor"]}
              open={Boolean(menuAnchor["sortByAnchor"])}
              onClick={() => closeMenu("sortByAnchor")}
            >
              <MenuItem
                onClick={() => {
                  setSort("id");
                  setSortingDirection("asc");
                }}
                disableRipple
              >
                <DefaultIcon />
                {t("Default")}
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={() => {
                  setSort("fullname.en");
                  setSortingDirection("asc");
                }}
                disableRipple
              >
                <PersonIcon />
                {t("A-Z")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSort("fullname.en");
                  setSortingDirection("desc");
                }}
                disableRipple
              >
                <CorporateFareIcon />
                {t("Z-A")}
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={() => {
                  setSort("dob");
                  setSortingDirection("desc");
                }}
                disableRipple
              >
                <FeedIcon /> {t("Age")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSort("dob");
                  setSortingDirection("asc");
                }}
                disableRipple
              >
                <BusinessIcon /> {t("Age")}
              </MenuItem>
            </FilterMenu>
            {(isDesktop || isCompactDesktop || isTabletLandscape) && (
              <div className="filtreContener">
                <Button
                  variant="contained"
                  disableElevation
                  className="FilterButton sortByButton"
                  onClick={(event) => openMenu("sortByAnchor", event)}
                  startIcon={<SortIcon />}
                  disabled={loading ? true : false}
                ></Button>
                <Button
                  variant="text"
                  className="FilterButton"
                  onClick={handleTab}
                  disabled={loading ? true : false}
                >
                  {t("Filters")}
                </Button>
                <Button
                  variant="text"
                  className="FilterButton"
                  disabled
                  onClick={handleStatsTab}
                >
                  {t("Stats")}
                </Button>
              </div>
            )}

            <div className="pagenav">
              {(isPhone || isTabletPortrait) && (
                <div className="buttons">
                  <IconButton
                    aria-label="Sort by Button"
                    onClick={(event) => openMenu("sortByAnchor", event)}
                  >
                    <SortIcon />
                  </IconButton>
                  <IconButton aria-label="Filter Button" onClick={handleTab}>
                    <FilterAltIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Filter Button"
                    onClick={() => setSearchPage(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                  <Button
                    variant="text"
                    className="FilterButton"
                    disabled
                    onClick={handleStatsTab}
                  >
                    {t("Stats")}
                  </Button>
                </div>
              )}
              <Stack spacing={2}>
                <Pagination
                  count={totalPages || 100}
                  page={currentPage}
                  siblingCount={isPhone ? 0 : 1}
                  onChange={(e, value) => {
                    setCurrentPage(value);
                    navigate(`/?page=${value}`);
                  }}
                />
              </Stack>
            </div>
          </div>

          {(!loading && (
            <>
              <div className={statsTab}>
                {/* <p>People: {stats.count_total}</p>
        <p>Males: {stats.count_male}</p>
        <p>Females: {stats.count_female}</p>
        <p>Avg age: {secondsToAge(stats.avg_age_total)}</p>
        <p>Male avg age: {secondsToAge(stats.avg_age_male)}</p>
        <p>Female avg age: {secondsToAge(stats.avg_age_female)}</p> */}
              </div>
              <div className={filtersTab}>
                <div className="filtersRow">
                  <SelectFilter
                    labelName={t("GENDER")}
                    optionsData={genderOptions}
                    setOption={setSex}
                  />
                  <SelectFilter
                    labelName={t("STATUS")}
                    optionsData={statusOptions}
                    setOption={setStatus}
                  />
                  <SelectFilter
                    labelName={t("AGE")}
                    optionsData={filtersAge}
                    setOption={setSelectedAge}
                  />
                </div>
                <div className="filtersRow">
                  <MultiSelectFilter
                    labelName={t("Flags")}
                    optionsData={flagsOptions()}
                    setOption={setFlagsIds}
                  />
                  <MultiSelectFilter
                    labelName={t("expertise")}
                    optionsData={expertsOptions()}
                    setOption={setExpertiseIds}
                  />
                  <MultiSelectFilter
                    labelName={t("groups")}
                    optionsData={groupsOptions()}
                    setOption={setGroupsIds}
                  />
                </div>
              </div>
              {persons?.length > 0 && (
                <section className="personlist">
                  {personsChunks?.map((chunk, index) => (
                    <div className="flexwrap" key={index}>
                      {chunk?.map((person) => (
                        <Person {...person} key={person.id} />
                      ))}
                    </div>
                  ))}
                </section>
              )}
            </>
          )) || <Loading />}

          {persons?.length <= 0 && loading === false && (
            <section className="noResults">
              <h3>no results are found</h3>
            </section>
          )}
          {persons?.length > 10 && loading === false && (
            <div className="buttomPaginationContainer">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages || 1}
                  page={currentPage}
                  siblingCount={isPhone ? 0 : 1}
                  onChange={(e, value) => {
                    setCurrentPage(value);
                    navigate(`/?page=${value}`);
                  }}
                />
              </Stack>
            </div>
          )}
        </div>
      )) || (
        <>
          <MobileSearch setSearchPage={setSearchPage} />
        </>
      )}
    </>
  );
}
