import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import TabsComponent from "../components/Tabs.jsx";
import { Button, Chip } from "@mui/material";
import log from "loglevel";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/uk";

import "../css/persondetails.scss";
// import "../css/them.scss";
import { personsUrl, requestPerson } from "../hooks/RestClient.jsx";
import Report from "../components/Report.jsx";

import Associates from "../components/Associates.jsx";
import { useTheme } from "../hooks/useThemeContext.jsx";
import Loading from "../components/Loading.jsx";
/**
 * Helper function to process values from the API
 * @param values
 * @returns {*|string}
 */

function processValues(values) {
  if (values === null) {
    return "";
  } else if (typeof values === "string") {
    return values;
  }
}

/** PersonDetails component
 * Renders detailed information about a person
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */

export const PersonDetails = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adrssvisibliti, setadrssvisibliti] = useState(false);

  const t = useTranslation().t;
  const theme = useTheme();

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await requestPerson(personsUrl(), id);

        if (res.status === 404) {
          navigate("/404");
        } else if (res.status === 500) {
          navigate("/500");
        } else if (res.error) {
          console.error(res.error); // Handle other errors or show a message
        }
        setData(res);
      } catch (error) {
        log.error(error);
      } finally {
        setLoading(false);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, [id, navigate]);

  const lang = localStorage.getItem("lang") || "en"; // default to English if lang is not set
  const fullnames = {
    en: data?.fullname?.en ?? "",
    ru: data?.fullname?.ru ?? "",
    uk: data?.fullname?.uk ?? "",
  };
  const fullname = processValues(fullnames[lang]);
  let runame = processValues(fullnames["ru"]);

  if (lang === "ru") {
    runame = "";
  }

  const dateFormat = lang == "en" ? "MMMM DD,YYYY" : "DD MMMM YYYY";
  const dob = dayjs(data.dob).locale(lang).format(dateFormat) || "";

  const sex = data.sex || "";
  const photo = data.photo || "";
  const social = data.social || [];

  const known_fors = {
    en: data.known_for?.en ?? "",
    ru: data.known_for?.ru ?? "",
    uk: data.known_for?.uk ?? "",
  };
  const knownFor = processValues(known_fors[lang]);

  // const infos = {
  //   en: data.info.en || "",
  //   ru: data.info.ru || "",
  //   uk: data.info.uk || "",
  // };
  // noinspection JSUnresolvedVariable
  // const info = processValues(infos[lang]);

  const contact = data.contact || {};
  const emails = contact.emails || {};
  const phones = contact.phones || {};
  const telegram = contact.telegram || {};
  const address = data.address || [];
  const associates = data.associates || [];
  const additional = data.additional || {};
  const additionalRaw = additional.raw || {};
  const additionalDrfo = additional.drfo || {};
  const additionalPid = additional.pid || {};
  const additionalEdrpou = additional.edrpou || {};
  const additionalTin = additional.tin || {};
  const additionalUrls = additional.urls || {};
  const bundles = data.bundles || {};
  const external_links = data.external_links || [];

  const age = data.dob
    ? `${new Date().getFullYear() - new Date(data.dob).getFullYear()}`
    : null;

  const socialFixed = social;
  const addressFixed = address;
  const external_linksFixed = external_links;

  const extractWebsiteName = (link) => {
    let hostname = "";
    try {
      const url = new URL(link);
      hostname = url.hostname;
    } catch (error) {
      console.error("Invalid URL:", link);
    }
    return hostname;
  };

  const renderLinks = (linksList) => {
    if (linksList && linksList.length > 0) {
      return (
        <div className="linkButton">
          {linksList.map((link, index) => (
            <div key={index}>
              <Button variant="text">
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {extractWebsiteName(link)}
                </a>
              </Button>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const ObjectList = ({ data, sectionName }) => {
    const [visibleButtons, setVisibleButtons] = useState({});

    const toggleTextVisibility = (index) => {
      setVisibleButtons((prevState) => ({
        ...prevState,
        [index]: true,
      }));
    };

    if (data && data.length > 0) {
      return (
        <span className="container">
          <h6>{sectionName}: </h6>
          {data?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Button
                  id={index}
                  variant="text"
                  color="primary"
                  onClick={() => toggleTextVisibility(index)}
                >
                  {visibleButtons[index] ? (
                    <span>
                      <p>{item}</p>
                    </span>
                  ) : (
                    <span
                      className={
                        theme === "dark-mode"
                          ? "spoilerTextDark"
                          : "spoilerText"
                      }
                    >
                      <p>{item}</p>
                    </span>
                  )}
                </Button>
              </React.Fragment>
            );
          })}
        </span>
      );
    }

    return null;
  };

  const RenderString = ({ data, sectionName }) => {
    if (data && data.length > 0) {
      return <h6>{sectionName + ": " + data} </h6>;
    }
    return null;
  };

  const Gender = () => {
    if (sex === "f") {
      return "Female";
    } else if (sex === "m") {
      return "Male";
    } else {
      return "- - -";
    }
  };

  return (
    <>
      {(!loading && (
        <div id="person-details">
          <aside>
            <div className="img">
              <img src={photo} alt={fullname} />
            </div>
            {isDesktopOrLaptop && (
              <>
                <div className="Biography">
                  <h3>{t("Biography")}:</h3>
                  <div className=" grid">
                    <h6>{t("Gender")}:</h6>
                    <p>{t(Gender())}</p>
                    <h6>{t("Born")}:</h6>
                    <p>{dob}</p>
                    <h6>{t("Age")}:</h6>
                    <p>
                      {age} {t("years old")}
                    </p>
                  </div>
                </div>
                {/* <div className="icon"></div> */}
                <div className="aliasesList">
                  {associates.map((associate, index) => {
                    const associateId = associate.id || "";
                    return (
                      <div key={index}>
                        {!associateId && (
                          <>
                            <h3>{t("Associates")}:</h3>
                            <div className="continer">
                              <div className="img">
                                <img
                                  src="https://res.cloudinary.com/dyndxow8x/image/upload/v1685462678/wapaganda/00000015_3_yewtvv.png"
                                  alt=""
                                />
                              </div>
                              <div className="details">
                                <p>{"Name: " + associate.name[lang]}</p>
                                <p>
                                  {"Relationship: " + associate.relationship}
                                </p>
                                <p>{"Born: " + associate.dob}</p>
                              </div>
                            </div>
                          </>
                        )}

                        {associateId && (
                          <>
                            {associates.map((associate) => (
                              <Associates
                                {...associate}
                                key={associate.id}
                                id={associate.id}
                              />
                            ))}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </aside>
          <main>
            <div className="name">
              <h2>{fullname}</h2>
              <h4>{runame}</h4>
            </div>
            <div className="about">
              <p>{knownFor}</p>
            </div>
            {isTabletOrMobile && (
              <>
                <div className="Biography">
                  <h3>{t("Biography")}:</h3>
                  <div className=" grid">
                    <h6>{t("Gender")}:</h6>
                    <p>{Gender()}</p>
                    <h6>{t("Born")}:</h6>
                    <p>{dob}</p>
                    <h6>{t("Age")}:</h6>
                    <p>{age}</p>
                  </div>
                </div>
                <div className="aliasesList">
                  {associates.map((associate, index) => {
                    const associateId = associate.id || "";
                    return (
                      <div key={index}>
                        {!associateId && (
                          <>
                            <h3>{t("Associates")}:</h3>
                            <div className="continer">
                              <div className="img">
                                <img
                                  src="https://res.cloudinary.com/dyndxow8x/image/upload/v1685462678/wapaganda/00000015_3_yewtvv.png"
                                  alt=""
                                />
                              </div>
                              <div className="details">
                                <p>{"Name: " + associate.name[lang]}</p>
                                <p>
                                  {"Relationship: " + associate.relationship}
                                </p>
                                <p>{"Born: " + associate.dob}</p>
                              </div>
                            </div>
                          </>
                        )}

                        {associateId && (
                          <>
                            {associates.map((associate) => (
                              <Associates
                                {...associate}
                                key={associate.id}
                                id={associate.id}
                              />
                            ))}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <div id="table">
              <table>
                <tbody>
                  {social.length > 0 && (
                    <tr>
                      <td>
                        <h4>{t("Social networks presence")}:</h4>
                      </td>
                      <td>{renderLinks(socialFixed)} </td>
                      <td className="reportInfo">
                        <Report />
                      </td>
                    </tr>
                  )}

                  {(emails.length > 0 ||
                    phones.length > 0 ||
                    telegram.length > 0) && (
                    <tr id="contact">
                      <td>
                        <h4>{t("Known contact information")}: </h4>
                      </td>
                      <td>
                        <ObjectList data={emails} sectionName={"Emails"} />
                        <ObjectList data={phones} sectionName={"Phones"} />
                        <ObjectList data={telegram} sectionName={"Telegram"} />
                      </td>
                      <td className="reportInfo">
                        <Report />
                      </td>
                    </tr>
                  )}

                  {addressFixed.length > 0 && (
                    <tr id="address">
                      <td>
                        <h4>{t("Known addresses")}: </h4>
                      </td>
                      <td>
                        <div
                          onClick={() => setadrssvisibliti(true)}
                          className={
                            !adrssvisibliti
                              ? theme === "dark-mode"
                                ? "spoiler-word-dark"
                                : "spoiler-word"
                              : "spoiler-word-deactvited"
                          }
                        >
                          {addressFixed.map((addres, addresIndex) => (
                            <span key={addresIndex}>{addres}</span>
                          ))}
                        </div>
                      </td>
                      <td className="reportInfo">
                        <Report />
                      </td>
                    </tr>
                  )}
                  {(additionalRaw.length > 0 ||
                    additionalDrfo.length > 0 ||
                    additionalPid.length > 0 ||
                    additionalEdrpou.length > 0 ||
                    additionalTin.length > 0 ||
                    additionalUrls.length > 0) && (
                    <tr>
                      <td>
                        <h4>{t("Additional details")}: </h4>
                      </td>
                      <td>
                        <RenderString
                          data={additionalRaw}
                          sectionName={"raw"}
                        />
                        <RenderString
                          data={additionalDrfo}
                          sectionName={"drfo"}
                        />
                        <RenderString
                          data={additionalPid}
                          sectionName={"pid"}
                        />
                        <RenderString
                          data={additionalEdrpou}
                          sectionName={"edrpou"}
                        />
                        <RenderString
                          data={additionalTin}
                          sectionName={"tin"}
                        />
                        {renderLinks(additionalUrls)}
                      </td>
                      <td className="reportInfo">
                        <Report />
                      </td>
                    </tr>
                  )}
                  {external_linksFixed.length > 0 && (
                    <tr>
                      <td>
                        <h4>{t("Also featured on")}:</h4>
                      </td>
                      <td>{renderLinks(external_linksFixed)}</td>
                      <td className="reportInfo">
                        <Report />
                      </td>
                    </tr>
                  )}
                  {bundles.length > 0 && (
                    <>
                      {bundles.some((item) => item.bundle_type === "e") && (
                        <tr>
                          <td>
                            <h4>{t("Claimed expertise")}:</h4>
                          </td>
                          <td className="tagContainer">
                            {bundles.map((item, index) => {
                              if (item.bundle_type === "e") {
                                return (
                                  <span className="tag" key={index}>
                                    <p>{item.bundle_name[lang]}</p>
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </td>
                          <td className="reportInfo">
                            <Report />
                          </td>
                        </tr>
                      )}
                      {bundles.some((item) => item.bundle_type === "n") && (
                        <tr>
                          <td>
                            <h4>{t("Has affiliation with")}:</h4>
                          </td>
                          <td className="tagContainer">
                            {bundles.map((item, index) => {
                              if (item.bundle_type === "n") {
                                return (
                                  <span className="tag" key={index}>
                                    <p>{item.bundle_name[lang]}</p>
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </td>
                          <td className="reportInfo">
                            <Report />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div id="tags">
              {bundles.length > 0 &&
                bundles.map((item, index) => (
                  <span className="tag" key={index}>
                    {item.bundle_type === "o" && (
                      <Chip label={item.bundle_name[lang]} />
                    )}
                    {item.bundle_type === "n" && (
                      <Chip label={item.bundle_name[lang]} />
                    )}
                  </span>
                ))}
            </div>
            <TabsComponent data={data} />
          </main>
        </div>
      )) || <Loading />}
    </>
  );
};
