import { useState, useEffect } from "react";
import { searchUrl, requestSearch } from "../hooks/RestClient.jsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../css/MobileSearch.scss";

import { useTheme } from "../hooks/useThemeContext.jsx";

import PersonIcon from "@mui/icons-material/Person";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SuggestionItem = ({ userInput }) => {
  const t = useTranslation().t;
  const lang = t("lang");

  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await requestSearch(searchUrl(), userInput);
        if (res.error) {
          console.error(res.error);
        }
        setSuggestions(res);
        console.log("res: ", res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userInput]);

  return (
    <div className="suggestionsList">
      {!loading ? (
        suggestions.length >= 1 ? (
          suggestions.map((suggestion) => {
            const name = suggestion?.flnm?.[lang];
            const orgname = suggestion?.nm?.[lang];
            const suggestionLink =
              suggestion.type == "person"
                ? `../person/${suggestion.idx}`
                : `../${suggestion.type}/${suggestion.idx}`;
            return (
              //todo add switch
              <Link to={suggestionLink} key={suggestion.idx}>
                {suggestion.type == "person" && (
                  <>
                    <PersonIcon />
                    {name}
                  </>
                )}
                {suggestion.type == "organization" && (
                  <>
                    <CorporateFareIcon />
                    {orgname}
                  </>
                )}
              </Link>
            );
          })
        ) : (
          <div className="holder">
            <p>no resualts {suggestions.length}</p>{" "}
          </div>
        )
      ) : (
        <div className="holder">
          <p>loading...</p>
        </div>
      )}
    </div>
  );
};

const MobileSearch = ({ setSearchPage }) => {
  const t = useTranslation().t;

  const [userInput, setUserInput] = useState("");

  return (
    <div className="SearchPageMobile">
      <Link onClick={() => setSearchPage(false)} className="back">
        <ArrowBackIcon /> <h3>back</h3>
      </Link>

      <div className={`search ${open}`}>
        <div className="inputcontiner">
          <p className="prefix">{t("Search")}</p>
          <input
            type="text"
            className="search_input"
            onChange={(e) => setUserInput(e.target.value)}
          />
        </div>
      </div>

      <div className="suggestionsBox">
        {userInput.length < 3 && (
          <div className="holder">
            <p>minmum 3 charchtes</p>
          </div>
        )}
        {userInput.length >= 3 && <SuggestionItem userInput={userInput} />}
      </div>
    </div>
  );
};

export default MobileSearch;
