import { useState, useEffect } from "react";
import { searchUrl, requestSearch } from "../hooks/RestClient.jsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../css/search.scss";

import PersonIcon from "@mui/icons-material/Person";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FeedIcon from "@mui/icons-material/Feed";
import BusinessIcon from "@mui/icons-material/Business";

const SuggestionItem = ({ userInput }) => {
  const t = useTranslation().t;
  const lang = t("lang");

  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await requestSearch(searchUrl(), userInput);
        if (res.error) {
          console.error(res.error);
        }
        setSuggestions(res);
        console.log("res: ", res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userInput]);

  return (
    <div className="suggestionsList">
      {!loading ? (
        suggestions.length >= 1 ? (
          suggestions.map((suggestion) => {
            const name = suggestion?.flnm?.[lang];
            const orgname = suggestion?.nm?.[lang];
            const suggestionLink =
              suggestion.type == "person"
                ? `../person/${suggestion.idx}`
                : `../${suggestion.type}/${suggestion.idx}`;
            return (
              //todo add switch
              <Link to={suggestionLink} key={suggestion.idx}>
                {suggestion.type == "person" && (
                  <>
                    <PersonIcon />
                    {name}
                  </>
                )}
                {suggestion.type == "organization" && (
                  <>
                    <CorporateFareIcon />
                    {orgname}
                  </>
                )}
              </Link>
            );
          })
        ) : (
          <p>no resualts</p>
        )
      ) : (
        <p>loading...</p>
      )}
    </div>
  );
};

function Search() {
  const t = useTranslation().t;

  const [open, setOpen] = useState("");
  const [userInput, setUserInput] = useState("");

  return (
    <div className={`search ${open}`}>
      <div className="inputcontiner">
        <p className="prefix">{t("Search")}</p>
        <input
          type="text"
          className="search_input"
          onChange={(e) => setUserInput(e.target.value)}
          onFocus={() => setOpen("open")}
          onBlur={() => setTimeout(() => setOpen(""), 110)}
        />
      </div>
      {userInput.length > 0 && (
        <>
          <div className="horizantalline">
            <hr />
          </div>
          <div className="suggestionsBox">
            {userInput.length < 3 && <p>minmum 3 charchtes</p>}
            {userInput.length >= 3 && <SuggestionItem userInput={userInput} />}
          </div>
        </>
      )}
    </div>
  );
}

export default Search;
